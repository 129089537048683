define("discourse/plugins/discourse-subscriptions/discourse/models/plan", ["exports", "@ember/object", "discourse-common/utils/decorators"], function (_exports, _object, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Plan = _object.default.extend(dt7948.p({
    amountDollars: (0, _object.computed)("unit_amount", {
      get() {
        return parseFloat(this.get("unit_amount") / 100).toFixed(2);
      },
      set(key, value) {
        const decimal = parseFloat(value) * 100;
        this.set("unit_amount", decimal);
        return value;
      }
    }),
    amountCNY: (0, _object.computed)("unit_amount_cny", {
      get() {
        return parseFloat(this.get("unit_amount_cny") / 100).toFixed(2);
      },
      set(key, value) {
        const decimal = parseFloat(value) * 100;
        this.set("unit_amount_cny", decimal);
        return value;
      }
    }),
    billingInterval(interval, isSystemRecurring, systemInterval) {
      return (interval ? interval : isSystemRecurring ? systemInterval : interval) || "one-time";
    },
    subscriptionRate(amountDollars, currency, interval) {
      return `${amountDollars} ${currency.toUpperCase()} / ${interval}`;
    },
    getFeatures(features) {
      return features;
    }
  }, [["method", "billingInterval", [(0, _decorators.default)("recurring.interval", "metadata.is_system_recurring", "metadata.system_recurring_interval")]], ["method", "subscriptionRate", [(0, _decorators.default)("amountDollars", "currency", "billingInterval")]], ["method", "getFeatures", [(0, _decorators.default)("features")]]]));
  var _default = _exports.default = Plan;
});