define("discourse/plugins/discourse-subscriptions/discourse/components/subscribe-payments", ["exports", "@ember/component", "discourse/plugins/discourse-subscriptions/discourse/models/transaction", "discourse/plugins/discourse-subscriptions/discourse/models/subscription", "discourse-common/utils/decorators", "@ember/service", "@ember/object", "discourse/lib/ajax", "@ember/template-factory"], function (_exports, _component, _transaction, _subscription, _decorators, _service, _object, _ajax, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div id="payment-request-button"></div>
  */
  {
    "id": "iH19n6Eh",
    "block": "[[[10,0],[14,1,\"payment-request-button\"],[12],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/components/subscribe-payments.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    dialog: (0, _service.inject)(),
    router: (0, _service.inject)(),
    redirectOnSuccess(result, plan) {
      result.complete('success');
      this.alert("plans.success");
      const location = plan.type === "recurring" ? "user.billing.subscriptions" : "user.billing.payments";
      const username = this.currentUser.username.toLowerCase();
      this.router.transitionTo(location, username);
    },
    async setupButtonElement() {
      const plan = this.plans.filterBy("id", this.selectedPlan).get("firstObject");
      if (!plan) {
        this.alert("plans.validate.payment_options.required");
        return;
      }
      if (this.selectedPlan) {
        const elements = this.stripe.elements();
        const paymentRequest = this.stripe.paymentRequest({
          currency: "usd",
          country: "US",
          requestPayerName: true,
          requestPayerEmail: true,
          total: {
            label: plan.subscriptionRate,
            amount: plan.unit_amount
          }
        });
        this.set("buttonElement", elements.create('paymentRequestButton', {
          paymentRequest: paymentRequest
        }));
        this.set("paymentRequest", paymentRequest);
      }
      this.paymentRequest.canMakePayment().then(result => {
        if (result) {
          setTimeout(() => {
            this.buttonElement.mount("#payment-request-button");
          }, 1000);
        } else {
          //hide the button
          // document.getElementById("payment-request-button").style.display = "none";
          console.log("GooglePay and ApplePay is unvailable");
        }
      });
      this.paymentRequest.on('token', result => {
        const subscription = _subscription.default.create({
          source: result.token.id,
          plan: plan.get("id"),
          promo: this.promoCode
        });
        subscription.save().then(save => {
          console.log(save);
          if (save.error) {
            this.dialog.alert(save.error.message || save.error);
          } else if (save.status === "incomplete" || save.status === "open") {
            const transactionId = save.id;
            const planId = this.selectedPlan;
            this.handleAuthentication(plan, save).then(authenticationResult => {
              if (authenticationResult && !authenticationResult.error) {
                return _transaction.default.finalize(transactionId, planId).then(() => {
                  this.send('redirectOnSuccess', result, plan);
                });
              }
            });
          } else {
            this.send('redirectOnSuccess', result, plan);
          }
        }).catch(error => {
          result.complete('fail');
          this.dialog.alert(error.jqXHR.responseJSON.errors[0] || error.errorThrown);
        });
      });
    },
    didInsertElement() {
      this._super(...arguments);
      console.log("I was called");
    }
  }, [["method", "redirectOnSuccess", [_object.action]], ["method", "setupButtonElement", [(0, _decorators.observes)("selectedPlan", "plans")]]])));
});