define("discourse/plugins/discourse-subscriptions/discourse/models/user-subscription", ["exports", "@ember/object", "discourse/lib/ajax", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-subscriptions/discourse/models/plan"], function (_exports, _object, _ajax, _decorators, _I18n, _plan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UserSubscription = _object.default.extend(dt7948.p({
    canceled(status) {
      return status === "canceled";
    },
    endDate(current_period_end, canceled_at) {
      if (!canceled_at) {
        return moment.unix(current_period_end).format("LL");
      } else {
        return _I18n.default.t("discourse_subscriptions.user.subscriptions.cancelled");
      }
    },
    discounted(discount) {
      if (discount) {
        const amount_off = discount.coupon.amount_off;
        const percent_off = discount.coupon.percent_off;
        if (amount_off) {
          return `${parseFloat(amount_off * 0.01).toFixed(2)}`;
        } else if (percent_off) {
          return `${percent_off}%`;
        }
      } else {
        return _I18n.default.t("no_value");
      }
    },
    destroy() {
      return (0, _ajax.ajax)(`/s/user/subscriptions/${this.id}`, {
        method: "delete"
      }).then(result => UserSubscription.create(result));
    },
    isSystemSubscription(recurring) {
      return recurring === 'true';
    },
    isInternallyCancelled(recurring) {
      return this.canceled;
    }
  }, [["method", "canceled", [(0, _decorators.default)("status")]], ["method", "endDate", [(0, _decorators.default)("current_period_end", "canceled_at")]], ["method", "discounted", [(0, _decorators.default)("discount")]], ["method", "isSystemSubscription", [(0, _decorators.default)("plan.metadata.is_system_recurring")]], ["method", "isInternallyCancelled", [(0, _decorators.default)("plan.metadata.is_system_recurring")]]]));
  UserSubscription.reopenClass({
    findAll() {
      return (0, _ajax.ajax)("/s/user/subscriptions", {
        method: "get"
      }).then(result => result.map(subscription => {
        subscription.plan = _plan.default.create(subscription.plan);
        return UserSubscription.create(subscription);
      }));
    }
  });
  var _default = _exports.default = UserSubscription;
});